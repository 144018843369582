.imageAvatar {
  img {
    width: 28px;
  }
  .ui.corner.label {
    margin-left: 0px !important;
    i.icon {
      font-size: 8px;
      left: -17px;
      top: 0px;
    }
    // width: 2em !important;
    // height: 2em !important;
  }
}
.ui.left.corner.label:after {
  border-top-width: 2em;
  border-right-width: 2em;
}

.StepItem {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .fieldInputLine {
    width: 100% !important;
    margin: 0px !important;
    margin-right: 16px !important;
  }
}

.StepItemContainer {
  margin-bottom: 8px;
}

.StepItemEditor {
  margin-top: 4px;
}
