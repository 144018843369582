.CourseView{
    margin: 0px !important;
    .row.Viewer{
        margin: 0px;
        padding: 0px;
        // min-height: 80vh;
        background: #F8F8F6;
        flex-direction: column;
        .ui.grid{
            margin: 16px 28px;
            .column{
                margin-left: 0px;
                padding-left: 0px;
                // color: #FFF;
            }
        }
        .TopBar{
            padding-top: 1rem;
            .column{
                margin: 0px;
                padding: 0px;
                // color: #FFF;
            }
        }
        video{
            width: 100%;
            height: 60vh;
            object-fit: contain;
            background: #F8F8F6;
            border: 0px;
        }
        
        .tiny.steps{
            border: 0px;
            .step{
                background: none;
                border: 0px;
                color: #000;
                display: flex;
                flex-direction: row;
                padding: 0px;
                margin-bottom: 16px;
                &:after{
                    display: none;
                }
                .title{
                    color: #000;
                }
                .description{
                    // color: rgba(255,255,255,0.75);
                }
                i.icon{
                    // color: rgba(255,255,255, 0.65);
                    margin-right: 16px;
                    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
                }
                &.subStep{
                    margin-top: -24px;
                    margin-bottom: 0px;
                    .content{
                        margin-left: 32px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.topObjetivesStep{
    h2{ 
        color: #307FE2;
    }
    i{
        color: #888 !important;
    }
    max-height: 40vh;
    overflow-y: scroll;
    .totalSteps{
        color: #333;
        float: right;
        margin-right: 16px;
        opacity: 0.45;
        font-size: 15px;
    }
}

.ComplementaryExtra{
    margin:0px !important;
    padding:0px !important;
    .column{
        padding: 24px !important;
    }
    h1,h2,h3,h4, .content{
        color: #307FE2;
    }
}
.MaterialAdicional{
//   background: rgba(7,23,42,0.10);
   background: rgba(206, 221, 237, 0.31);
}

.ObjetiveStep{
    padding-left: 24px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between;
    height: 100%;
}

.isMobile {
    video {
        height: 50vh !important;
        object-fit: contain !important;
    }
    .ObjetiveStep{
        margin-top: 24px !important;
    }
}

.footerVideo{
    strong {
        color: #307FE2;
    }
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: stretch;
    & > div {
        width: 100%;
    }
    .rateSystem{
//         color: rgba(255,255,255,.65);
        justify-content: flex-start;
        flex-direction: column;
        display: flex;
        .rating{
            display: flex;
            flex-direction: row;
            width: 10%;
            align-items: flex-start;
        }
        // i{
            // color: rgba(255,255,255,.35);
        // }
        margin-bottom: 16px;
    }
    .relatedCapsules{
        // color: rgba(255,255,255,.65);
        padding-top: 0px;
        .list{
            display: flex;
            flex-wrap: wrap;
            .item{
                // color: rgba(255,255,255,.85);
                width: 50%;
                padding-top: 0px;
                margin-bottom: 4px;
                // cursor: pointer;
                a{
                    color: #000;
                }
            }
        }
    }
}



.RowFooterVideo{
    background: #3F4444;
}

.ui.grid>.row.TitleName{
    background: #cedded4f !important;
    margin-left: -24px !important;
    padding-left: 24px !important;
    width: calc(100vw) !important;
    outline: 24px #cedded4f;
    border: 24px #cedded4f;
    h2 {
        color: #307FE2 !important;
    }
}

.ui.steps .step>.icon~.content {
    flex: 1;
}