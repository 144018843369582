.ViewCourses {
    margin: 0px !important;
    margin-top: 16px !important;
    margin-left: 28px !important;
    margin-right: 28px !important;
    
    .row{
        .ui.fluid.secondary.menu{
            border-bottom-width: 3px;
            .item{
                font-size: 12px;
                text-transform: uppercase !important;
                font-weight: bold;
                color: #8F9BB3;
                &.active{
                    border-bottom-width: 3px;
                    color: #317EE0;
                    border-color: #317EE0;
                }
            }
        }
        .SearchBar{
            input{
                background: #F7F9FC;
                &::placeholder{
                    color: #8F9BB3 !important;
                }
            }
        }
    }
    .row.CourseList{
        display: flex;
        flex-direction: row;
        .CategoryWrapper{
            width: 100%;
            margin-top: 48px;
            &:first-child{
                margin-top: 0px;
            }
            div.ui.four.cards{
                margin-top: 24px;
                
            }
        }
        &.Searching{
            .ui.header{
                width: 100%;
            }
            form.ui.equal.width.form{
                width: 100%;
                input[type=text]{
                    background: #F7F9FC;
                    &::placeholder{
                        color: #8F9BB3 !important;
                    }
                }
                .SearchInputBar{
                    flex-grow: 5;
                    flex-basis: 50%;
                }
                .LearningRouteSelector{
                    flex-grow: 3;
                    flex-basis: 30%;
                }
                .BuscarBtn{
                    flex-grow: 1;
                    flex-basis: 10%;
                    button{
                       height: 100%;
                    }
                    text-align: right;
                }
            }
        }
        .ui.card{
            margin-top: 18px;
            &.DisabledCard{
                img{
                    filter:url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter     id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /*     Firefox 10+, Firefox on Android */
                    filter:gray; /* IE6-9 */
                    -webkit-filter:grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
                    -moz-filter: grayscale(100%);
                    -o-filter: grayscale(100%);
                    filter: grayscale(100%);
                }
            }
            .extra{
                text-align: right;
            }
        }
    }
    .emptySearch{
        width: 100%;
    }
}

.isMobile {
    .CategoryWrapper {
        & > h2.ui.header{
            font-size: 20px;
        }
    }
}