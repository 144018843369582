.ViewHome{
    h2,h3,p,label{ user-select: none; }
    // label{ user-select: contain; }
    &.isDesktop{
        .ui.grid {
            margin: 0px !important;
            .column:not(.row){
                height: 100vh;
                &:first-child{
                    box-shadow: 0px 0px 24px rgba(0,0,0,0.08);
                    background: rgba(255,255,255,0.9);
                    display: flex;
                    flex-direction: column;
                    padding: 28px;
                    justify-content: center;
                    svg{
                        position: absolute;
                        top: 30px;
                        left: 28px;
                    }
                }
                &:last-child{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    text-align: center;
                    padding: 0px 64px;
                    .VideoWelcome{
                        cursor: pointer;
                        svg{
                            align-self: center;
                        }
                        i{
                            font-size: 64px;
                            position: absolute;
                            margin-left: 175px;
                            margin-top: 95px;
                            color: rgba(255,255,255,0.75);
                            transition: all ease 400ms;
                        }
                        &:hover i{
                            color: rgba(255,255,255,1);
                        }
                    }
                }
            }
            .column.row{
                margin: 0px;
                padding: 0px;
            }
        }
    }

    .videoWelcome{
        height: 220px;
        z-index: -1;
        // position: relative;
        width: 420px;
        object-fit: cover;
        box-shadow: 0px 0px 10px #000;
        // margin-left: -223px;
    }

    &.isMobile{
        .ui.grid{
            height: 100vh;
            .two.column{
                .column:first-child{
                    background: rgba(255,255,255,0.9);
                    display: flex;
                    flex-direction: column;
                    padding: 48px;
                    justify-content: center;
                    width: 100% !important;
                    svg{
                        position: absolute;
                        top: 48px;
                        left: 48px;
                    }
                    .ui.form{
                        margin-top: 24px;
                    }
                    button{
                        width: 100%;
                    }
                }
                .column:last-child{
                    display: none;
                }
            }
        }
    }
}

.videoModal{
    width: 100%;
}

.VideoDCVWelcome{
    padding: 0px !important;
    margin: 0px;
    background: #000;
}